import SnackBar, { TIconSnackbar } from '@atoms/SnackBar/SnackBar';
import { StyledSnackbarWrapper } from '@atoms/SnackBar/SnackBar.styles';
import { createContext, ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { UrlObject } from 'url';
import Config from '@config';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';

type SnackbarType = {
  id?: string;
  text: string;
  bottomPosition?: number;
  icon: TIconSnackbar;
  callback?: () => void;
  link?: string | UrlObject;
  linkText?: string;
  onClick?: () => void;
  onClose?: () => void;
  asProp?: string | UrlObject;
};

// Context
const SnackbarContext = createContext<(snack: SnackbarType) => void>(() => {
  /* no op */
});

export interface SnackbarProviderProps {
  children: ReactElement;
}

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [snacks, setSnacks] = useState<SnackbarType[]>([]);
  const snackbarRef = useRef<HTMLDivElement>(null);
  const isCartOpen = useAppSelector(selectMiniCartPreviewIsOpen);

  const addSnack = useCallback((s: SnackbarType) => {
    setSnacks((prevSnacks) => {
      const existingSnackIndex = prevSnacks.findIndex((snack) => (s.id ? snack.id === s.id : snack.text === s.text));
      if (existingSnackIndex !== -1) {
        const updatedSnacks = [...prevSnacks];
        updatedSnacks[existingSnackIndex] = s;
        return updatedSnacks;
      }
      return [...prevSnacks, s];
    });
  }, []);

  const removeSnack = useCallback((index: number) => {
    setSnacks((prevSnacks) => prevSnacks.filter((_, i) => i !== index));
  }, []);

  // Keyboard shortcut for Alt + G
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.altKey && event.code === 'KeyG') {
        event.preventDefault();
        snackbarRef.current?.querySelector<HTMLButtonElement>(Config.SELECTORS.focusableElements)?.focus();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <SnackbarContext.Provider value={addSnack}>
      {children}
      {snacks.length > 0 && (
        <StyledSnackbarWrapper isCartOpen={isCartOpen} tabIndex={1}>
          {snacks.map((snack, index) => (
            <SnackBar
              key={index}
              bottomPosition={snack.bottomPosition}
              text={snack.text}
              show={(show) => {
                if (!show) removeSnack(index);
              }}
              callback={snack.callback}
              icon={snack.icon}
              link={snack.link}
              linkText={snack.linkText}
              onClick={snack.onClick}
              onClose={snack.onClose}
              asProp={snack.asProp}
              // Assign the ref only to the last snackbar
              ref={index === snacks.length - 1 ? snackbarRef : undefined}
            />
          ))}
        </StyledSnackbarWrapper>
      )}
    </SnackbarContext.Provider>
  );
};

const useSnackBar = () => useContext(SnackbarContext);

export default useSnackBar;
